<template>
  <v-dialog v-model="getImageViewer.show" width="63%" persistent>
    <v-card width="100%">
      <v-row no-gutters>
        <v-col cols="12">
          <v-card-title>
            <v-row>
              <v-col align="center" cols="11">
                <span class="text-h5 pl-16 font-weight-bold">{{ getImageViewer.name }}</span>
              </v-col>
              <v-col cols="1" align="center" class="pa-0">
                <v-progress-circular @click="closeDialog()" :rotate="180" :size="50" :width="7" :value="closePercentage"
                  color="error" class="my-2">
                  <v-icon large color="error"> mdi-close </v-icon>
                </v-progress-circular>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider class="mx-2"></v-divider>
          <v-row no-gutters v-if="getImageViewer.floor !== undefined">
            <v-col cols="8" class="mb-10">
              <v-img :src="getImageViewer.url" width="100%" height="650"></v-img>
            </v-col>
            <v-col cols="4" align="center">
              <v-card width="80%" class="mt-5" color="blue-grey lighten-5" flat>
                <v-card-title class="d-flex align-center justify-center mb-0 pb-0">Directions</v-card-title>
                <v-divider class="mx-2 mt-2"></v-divider>
                <v-row class="ma-2">
                  <v-col cols="12" align="start">
                    <span class="text-subtitle-1">Floor: <v-chip class="px-4 font-weight-bold text-subtitle-1">{{
                      getImageViewer.floor }}</v-chip></span>
                  </v-col>
                  <v-col cols="12" align="start" v-if="getImageViewer.tower === 'Admin'">
                    <span v-if="getImageViewer.floor === 'Level 1'"> <strong> {{ getImageViewer.name }} </strong> is on
                      the same level. </span>
                    <span v-else>Please use the <strong>elevators</strong> located to
                      your
                      <strong>right</strong>.</span>
                      <v-img src="@/assets/adminElevator.gif" contain class="my-3" v-if="getImageViewer.floor !== 'Level 1'"></v-img>
                  </v-col>
                  <v-col cols="12" align="start" v-if="getImageViewer.tower === 'Judicial'">
                    <span v-if="getImageViewer.floor === 'Level 1'"> Please go to the
                      <strong>Judicial
                        Tower</strong>.</span>
                    <span v-else>Please use the <strong>elevators</strong> in the
                      <strong>Judicial
                        Tower</strong>.</span>
                    <v-img src="@/assets/judicialElevator.gif" contain class="my-3" v-if="getImageViewer.floor !== 'Level 1'"></v-img>
                  </v-col>
                </v-row>
              </v-card>
              <!-- <v-card width="80%" class="mt-5 pa-2" color="blue-grey lighten-5" flat
                v-if="getImageViewer.tower === 'Judicial'">
               

              </v-card> -->
            </v-col>
          </v-row>
          <v-row no-gutters v-else>
            <v-col cols="12" class="mb-10">
              <v-img :src="getImageViewer.url" width="98%"></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "imageViewer",
  data() {
    return {
      closePercentage: 0,
      timeout: 0,
      intervalObj: 0,
    };
  },
  computed: {
    ...mapGetters(["getImageViewer", "getIsSpeaking"]),
  },
  watch: {
    getIsSpeaking(newVal) {
      if (this.getImageViewer.show) {
        if (!newVal) {
          this.intervalObj = setInterval(() => {
            this.closePercentage += 2;
            if (this.closePercentage === 100) {
              clearInterval(this.intervalObj);
              this.closePercentage = 0;
              this.$store.commit("closeImageViewer");
            }
          }, 250);
        } else {
          this.closePercentage = 0;
          clearInterval(this.intervalObj);
        }
      }
    },
  },
  methods: {
    closeDialog() {
      clearInterval(this.intervalObj);
      this.closePercentage = 0;
      this.$store.commit("closeImageViewer");
    }
  },
  mounted() {
    console.log('Image viewer contents on mounted: ', this.getImageViewer)
  }
};
</script>

<style scoped>
.cardLeftBorder {
  border-right: 2px !important;
  border-top: 0px !important;
  border-bottom: 0px !important;
  border-radius: 0px;
  border-style: solid;
  border-width: 2px;
  border-color: black;
}
</style>
