<template>
    <v-row no-gutters>
        <v-col cols="12" align="center">
            <v-img src="@/assets/logo.png" contain width="700"></v-img>
        </v-col>
        <v-col cols="12" align="center">
            <v-text-field ref="textField" outlined :placeholder="$i18n.t('placeHolder')" rounded height="55px"
                class="text-h5 mt-8 searchBarClass" hide-details :value="searchKeyword" v-model="searchKeyword"
                clearable autofocus>
            </v-text-field>
        </v-col>
        <v-col cols="9" align="center">
            <v-card class="pa-4 containerBorder mt-8" width="95%" flat>
                <v-data-table hide-default-footer :items-per-page="-1" :height="viewBoxHeight" :headers="header"
                    :items="list" :loading="loading" loading-text="Fetching latest data. Please wait..." fixed-header>
                    <template v-slot:item.caseNumber="{ item }">
                        <strong class="text-subtitle-1 font-weight-bold">{{ item.caseNumber }}</strong>
                    </template>
                    <template v-slot:item.partyName="{ item }">
                        <div class="my-7" v-if="item.partyName === ''"> - </div>
                        <div class="my-7 text-subtitle-1" v-else>
                            {{ item.partyName }}
                        </div>
                    </template>
                    <template v-slot:item.hearingLocation="{ item }">
                        <v-btn @click="handleCourtLocation(item.hearingLocation)" width="100%" elevation="0"
                            color="primary" outlined>
                            <v-icon left>mdi-gesture-tap</v-icon>
                            {{ item.hearingLocation | trimLength }}
                        </v-btn>
                    </template>
                    <template v-slot:item.hearingTime="{ item }">
                        <span class="text-subtitle-1">{{ item.hearingTime | dateAndTime }}</span>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
        <v-col cols="3">
            <v-card class="containerBorder mt-8" width="95%" :height="viewBoxHeight + 38">
                <v-card-title
                    class="text-h6 font-weight-bold text-decoration-underline d-flex align-center justify-center">
                    Courtroom Location </v-card-title>
                <v-row no-gutters>
                    <v-col cols="12" align="center">
                        <v-card :height="viewBoxHeight - 35" class="overflow-y-auto" flat>
                            <v-card v-for="(courtroom, i) in courtroomLocations" :key="i" color="primary"
                                class="white--text d-flex align-center justify-center my-4" height="45" width="85%"
                                @click="openMap(courtroom)">
                                {{ courtroom.mapName }}
                            </v-card>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
import Todayshearing from '../views/todayshearing.vue';
import moment from "moment";
import avatar from '../components/avatar.vue';
export default {
    name: "hearingMapInfo",
    components: {
        Todayshearing,
        avatar
    },
    data() {
        return {
            searchKeyword: null,
            viewBoxHeight: 750,
            loading: true,
            header: [
                {
                    text: "Case Number",
                    align: "center",
                    value: "caseNumber",
                    width: "200",
                    sortable: false,
                    class: "text-h6 font-weight-black text-decoration-underline",
                },
                {
                    text: "Parties Name",
                    align: "start",
                    value: "partyName",
                    width: "450",
                    sortable: false,
                    class: "text-h6 font-weight-black text-decoration-underline",
                },
                {
                    text: "Court Room Location",
                    align: "center",
                    value: "hearingLocation",
                    width: "300",
                    sortable: false,
                    class: "text-h6 font-weight-black text-decoration-underline",
                },
                {
                    text: "Date/Time",
                    align: "center",
                    value: "hearingTime",
                    sortable: false,
                    class: "text-h6 font-weight-black text-decoration-underline",
                },
            ],
        }
    },
    computed: {
        ...mapGetters(["hearingInfo", "getTouchCount", "getKioskProfile", "getSessionId"]),
        list() {
            if (this.searchKeyword === "" || this.searchKeyword === null) {
                console.log('Hearing Data: ', this.hearingInfo)
                return this.hearingInfo;
            } else {
                let temp = [];
                // search by  party name or casenumber
                this.hearingInfo.forEach(caseInfo => {
                    if (caseInfo.caseNumber.toLowerCase().includes(this.searchKeyword.toLowerCase()) || caseInfo.partyName.toLowerCase().includes(this.searchKeyword.toLowerCase())) {
                        temp.push(caseInfo)
                    }
                })
                return temp;
            }
        },
        courtroomLocations() {
            console.log('Maps: ', this.$i18n.t("maps"))
            return this.$i18n.t("maps").filter(map => map.showOnHomepage === true)
        }
    },
    watch: {
        getSessionId(newVal) {
            this.searchKeyword = null
        }
    },
    methods: {
        handleCourtLocation(location) {
            //this.$store.dispatch("requestQnA", location);
            console.log('Location at courtroom location: ', location)
            this.$store.commit("setIsSpeaking", true);
            setTimeout(() => { this.$store.commit("setIsSpeaking", false); }, 15000)
            this.$store.commit('setTouchCount', this.getTouchCount + 1)
            this.$i18n.t("maps").forEach(mapper => {
                if (mapper.mapName.toLowerCase().trim() === location.toLowerCase().trim()) {
                    console.log('Clicked Map', mapper)

                    this.$store.dispatch('openImageViewer', {
                        name: mapper.mapName,
                        url: mapper.mapImage,
                        floor: this.$i18n.t("floors").filter(floor => floor.floorNumber === mapper.mapFloor)[0].inMapDisplay,
                        tower: mapper.tower
                    })
                    this.$store.dispatch('avatarSpeak', mapper.speech);
                }
            })
            const date = new Date()
            if (this.getTouchCount === 1) {
                this.$store.dispatch('addUserSessionToStrapi', {
                    applicationId: this.getKioskProfile.data().applicationId,
                    actionType: 'Session Start',
                    applicationSessionId: this.getSessionId,
                    timeStamp: date.toISOString(),
                    sessionPayload: {
                        module: "Default",
                        action: "Touch Detected",
                        response: "User Session Started",
                        timeStamp: date.toISOString()
                    }
                })
            }


            this.$store.dispatch('addUserSessionToStrapi', {
                applicationId: this.getKioskProfile.data().applicationId,
                actionType: 'Touch',
                applicationSessionId: this.getSessionId,
                timeStamp: date.toISOString(),
                sessionPayload: {
                    module: "Today's Hearing",
                    action: "Show Map",
                    response: location,
                    timeStamp: date.toISOString()
                }
            })
        },
        openMap(courtroom) {
            this.$store.commit("setIsSpeaking", true);
            setTimeout(() => { this.$store.commit("setIsSpeaking", false); }, 15000)
            this.$store.commit('setTouchCount', this.getTouchCount + 1)
            console.log('AT OPEN COURTROOM: ', courtroom)
            this.$store.dispatch('openImageViewer', {
                name: courtroom.mapName,
                url: courtroom.mapImage,
                floor: this.$i18n.t("floors").filter(floor => floor.floorNumber === courtroom.mapFloor)[0].inMapDisplay,
                tower: courtroom.tower
            })
            const date = new Date()

            if (this.getTouchCount === 1) {
                this.$store.dispatch('addUserSessionToStrapi', {
                    applicationId: this.getKioskProfile.data().applicationId,
                    actionType: 'Session Start',
                    applicationSessionId: this.getSessionId,
                    timeStamp: date.toISOString(),
                    sessionPayload: {
                        module: "Default",
                        action: "Touch Detected",
                        response: "User Session Started",
                        timeStamp: date.toISOString()
                    }
                })
            }

            this.$store.dispatch('addUserSessionToStrapi', {
                applicationId: this.getKioskProfile.data().applicationId,
                actionType: 'Touch',
                applicationSessionId: this.getSessionId,
                timeStamp: date.toISOString(),
                sessionPayload: {
                    module: "Today's Hearing",
                    action: "Show Map",
                    response: courtroom.mapName,
                    timeStamp: date.toISOString()
                }
            })
        }
    },
    filters: {
        dateAndTime(val) {
            if (!val) {
                return "-";
            }

            return 'Today - ' + moment(val, ['hmmA', 'h:mmA']).format('h:mm A')
            //return 'Today - ' + parsedTime.toLocaleString('en-US', options);
        },
        trimLength(val) {
            if (val === null || val === undefined) return val;
            if (val.length <= 30) {
                return val;
            }
            return `${val.substring(0, 23)}...`;
        },
    },
    mounted() {
        const inputElement = this.$refs.textField.$refs.input;

        const keepFocus = () => {
            inputElement.focus();
        };

        // Add an event listener to refocus the input when it loses focus
        inputElement.addEventListener("blur", keepFocus);

        // Ensure it starts focused
        inputElement.focus();
        this.$store.dispatch("fetchingHearingInfo").then(response => {
            console.log('Response from hearing action:')
            if (response) {
                this.loading = false
            }
        });

        setInterval(() => {
            this.loading = true
            this.$store.dispatch("fetchingHearingInfo").then(response => {
                console.log('Response from hearing action:')
                if (response) {
                    this.loading = false
                }
            });
        }, 300000)
    }
}
</script>
<style>
.searchBarClass {
    width: 1000px !important;
}
</style>
<i18n>
    {
      "en": {
          "placeHolder": "Tap to search by Full Name or Case Number"
        },
      "es":{
          "placeHolder": "Toque para buscar por nombre completo o número de caso"
        }
    }
  </i18n>